export default defineNuxtRouteMiddleware(async (_to, _from) => {
	// skip middleware on server
	if (import.meta.server) return;
	// skip middleware on client side entirely
	// if (import.meta.client) return;

	// or only skip middleware on initial client load
	// const nuxtApp = useNuxtApp();
	// if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return;
	const purchaseTicketStore = usePurchaseTicketStore();
	const { purchaseTicket } = storeToRefs(purchaseTicketStore);

	if (!purchaseTicket.value.event) {
		const eventStore = useEventStore();
		await eventStore.getEventById(useRoute().params.id);
		const { eventDetails } = storeToRefs(eventStore);

		purchaseTicketStore.updateEvent(eventDetails.value);
	}
});
